import React, { useState, useEffect } from "react";
import Card from "../../components/Cards";
import Layout from "../../components/Layout";
import EditIcon from "../../assets/svgs/pencil.inline.svg";
import SEO from "../../components/SEO";
import InputSearch from "../../components/Input/InputSearch";
import ModalVehicleEdit from "./components/ModalVehicleEdit";
import ModalVehicleRegister from "./components/ModalVehicleRegister";
import requests from "../../services/requests";
import Modal from "../../components/Modals";
import ModalVehicleRemove from "./components/ModalVehicleRemove";
import { ClipLoader } from "react-spinners";
import Paginate from "../../components/Paginate/Paginate";
import Button from "../../components/Button";
import { FiDownload } from "react-icons/fi";
import api from "../../services/api";
import { FaTrashAlt } from "react-icons/fa";

const VehicleManagement = () => {
    const [currentVehicle, setSelectedVehicle] = useState({});
    const [load, setLoad] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [openModalRemove, setOpenModalRemove] = useState(false);
    const [tabModal, setTabModal] = useState("Cadastro de veículo");
    const [tabModalEdit, setTabModalEdit] = useState("Edição de veículo");
    const [tabModalRemove, setTabModalRemove] = useState("Remover veículo");
    const [list, setList] = useState([]);
    const [modalExport, setModalExport] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [action, setAction] = useState(1);
    const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);
    const [meta, setMeta] = useState({
        current_page: 1,
    });

    useEffect(() => {
        loadList();
    }, [searchString, action]);

    const loadList = () => {
        setLoad(true);
        requests
            .listVehicles(
                {
                    search: searchString,
                },
                meta.current_page > 0 ? meta.current_page : 1
            )
            .then(list => {
                setList(list.data);
                setMeta(list.meta);
                setLoad(false);
            });
    };

    const actionModalExport = () => {
        setModalExport(!modalExport);
    };

    const downloadCsv = () => {
        setIsDownloadingCsv(true);

        var params = "";
        if (searchString.length > 0) {
            params += `search=${searchString}&`;
        }

        api.get(`adm/driver-freight/export-csv?${params}`, {
            responseType: "blob",
            headers: {
                Authorization: "Bearer " + window.localStorage.getItem("session-token"),
            },
        })
            .then(response => {
                const fileType = response.headers["content-type"];
                const blob = new Blob([response.data], { fileType });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "export.csv");
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .finally(() => {
                setIsDownloadingCsv(false);
            });
    };

    const onChangeSearchString = e => {
        let search = e.target.value.trim();
        setSearchString("");
        if (search.length > 2) {
            setSearchString(search);
        }
    };

    const actionModal = () => {
        setOpenModal(!openModal);
    };

    const closeModal = () => {
        setOpenModal(false);
    };

    const actionModalEdit = vehicle => {
        setSelectedVehicle(vehicle);
        setOpenModalEdit(!openModalEdit);
    };

    const actionModalRemove = vehicle => {
        setSelectedVehicle(vehicle);
        setOpenModalRemove(!openModalRemove);
    };

    const closeModalEdit = () => {
        setOpenModalEdit(false);
    };

    const closeModalRemove = () => {
        setOpenModalRemove(false);
    };

    return (
        <Layout>
            <ModalVehicleRegister
                actionModal={closeModal}
                tabModal={tabModal}
                setTabModal={setTabModal}
                openModal={openModal}
                handleSuccess={() => {
                    closeModal();
                    loadList();
                }}
            />
            <ModalVehicleEdit
                handleSuccess={() => {
                    closeModalEdit();
                    loadList();
                }}
                actionModal={closeModalEdit}
                tabModal={tabModalEdit}
                setTabModal={setTabModalEdit}
                openModal={openModalEdit}
                selectedEntity={currentVehicle}
                loadList={loadList}
            />
            <ModalVehicleRemove
                actionModal={closeModalRemove}
                tabModal={tabModalRemove}
                setTabModal={setTabModalRemove}
                openModal={openModalRemove}
                selectedEntity={currentVehicle}
                handleSuccess={() => {
                    closeModalRemove();
                    loadList();
                }}
            />
            <SEO title="Gestão de veículos" />
            <div className="p-4">
                <Card>
                    <div className="flex justify-between border-b border-C2 pb-4">
                        <div className="float-right" />
                        <div className="flex">
                            <button className="bg-roxo_oficial text-white px-4 py-1 rounded-md mr-4" onClick={actionModal}>
                                Cadastrar veículo
                            </button>
                            <Button
                                className="mr-4 ml-px font-medium h-10 bg-roxo_oficial"
                                onClick={() => setModalExport(true)}
                            >
                                <FiDownload size="1.1rem" className="text-white" />{" "}
                                <div className="text-white ml-2">exportar</div>
                            </Button>
                        </div>
                    </div>
                    <div className="flex justify-between my-4">
                        <h5 className="w-6/12 uppercase text-C6">Listagem de veículos</h5>
                        <div className="flex w-6/12 justify-between float-right">
                            <div className="float-right" />
                            <div className="w-6/12">
                                <InputSearch placeholder="buscar" className="h-8 w-48" onChange={onChangeSearchString} />
                            </div>
                        </div>
                    </div>

                    {load ? (
                        <div className="flex flex-col items-center">
                            <ClipLoader size={20} loading={load} />
                        </div>
                    ) : (
                        <table className="table-auto w-full">
                            <thead className="border-b border-roxo_oficial">
                                <tr>
                                    <th className="text-left min-w-5 text-roxo_oficial font-normal">Placa</th>
                                    <th className="text-left min-w-5 text-roxo_oficial font-normal">Motorista</th>
                                    <th className="text-left min-w-5 text-roxo_oficial font-normal">Propriedade</th>
                                    <th className="text-left min-w-5 text-roxo_oficial font-normal">Data de cadastro</th>
                                    <th className="w-20 text-left text-roxo_oficial font-normal">Ações</th>
                                </tr>
                            </thead>
                            <tbody className="pt-2">
                                {list.map((data, key) => {
                                    return (
                                        <tr key={data.identifier}>
                                            <td className="text-left font-normal">{data.licensePlate}</td>
                                            <td className="text-left font-normal">{data.userDriverName}</td>
                                            <td className="text-left font-normal">{data.ownership}</td>
                                            <td className="text-left font-normal">{data.createdAtFormatted}</td>
                                            <td className="w-20 text-left font-normal">
                                                <>
                                                    <button
                                                        className="p-2 mt-2 mr-2 rounded-md bg-roxo_oficial_light"
                                                        onClick={() => actionModalEdit(data)}
                                                        title="Editar veículo"
                                                    >
                                                        <EditIcon />
                                                    </button>
                                                    <button
                                                        className="p-2 mt-2 rounded-md bg-canal_vermelho"
                                                        onClick={() => actionModalRemove(data)}
                                                        title="Remover veículo"
                                                    >
                                                        <FaTrashAlt color="#fff" />
                                                    </button>
                                                </>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                    {!load && list.length === 0 && <p className="text-center mt-3">Nenhum veículo encontrado</p>}
                    {meta.total_pages > 1 && (
                        <Paginate meta={meta} setMeta={setMeta} action={action} setAction={setAction} showDetails={true} />
                    )}
                </Card>
            </div>
            <Modal show={modalExport} borderTitle={false} onClose={actionModalExport} size="" title="">
                <div className="w-full text-center mb-4 mr-4">
                    <h2 className="font-medium text-roxo_oficial">Exportar Usuários</h2>
                    <button
                        disabled={isDownloadingCsv}
                        onClick={downloadCsv}
                        className="mt-4 px-3 py-1 font-medium rounded h-10 w-24 bg-roxo_oficial text-white"
                    >
                        {isDownloadingCsv ? (
                            <>
                                <ClipLoader color="white" size={20} loading={isDownloadingCsv} /> CSV
                            </>
                        ) : (
                            <>
                                <FiDownload size="1.5rem" className="inline-block" /> CSV
                            </>
                        )}
                    </button>
                </div>
            </Modal>
        </Layout>
    );
};

export default VehicleManagement;
